<template>
  <el-container class="app-container">
    <el-header class="header">
      <svg xmlns="http://www.w3.org/2000/svg" class="header-svg" viewBox="0 0 200 50" height="40">
        <rect width="200" height="50" fill="none" />
        <text x="10" y="35" class="text-svg">
          Test<tspan class="highlight">2</tspan>API
        </text>
      </svg>
    </el-header>
    <el-main class="main-content">
      <h1 class="title" style="text-align: center;">Claude\ChatGPT免费账号</h1>
      <el-tabs v-model="activeName" class="demo-tabs" :stretch="true" @tab-click="handleClick">
        <el-tab-pane label="Claude" name="Claude" class="full-height-pane">
          <el-row v-loading="loading" :gutter="24">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(url, index) in claudeLoginUrls" :key="index">
              <el-card class="account-card" shadow="hover">
                <template #header>
                  <div class="card-header">
                    <span>账号 {{ index }}</span>
                  </div>
                </template>
                <el-button type="primary" @click="openClaudeLoginUrl(url)" class="login-button">登录</el-button>
              </el-card>
            </el-col>
          </el-row>


        </el-tab-pane>
        <el-tab-pane label="ChatGPT" name="Chatgpt" class="full-height-pane">
          <el-row v-loading="loading" :gutter="24">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-for="(url, index) in gptUrls" :key="index">
              <el-card class="account-card" shadow="hover">
                <template #header>
                  <div class="card-header">
                    <span>账号 {{ index}}</span>
                  </div>
                </template>
                <el-button type="primary" @click="openLoginUrl(url)" class="login-button">登录</el-button>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-footer class="footer">
      <p class="text-center">© <a href="https://t.me/test2api" class="footer-link">test2api</a></p>
    </el-footer>
  </el-container>
</template>


<script>
  import { ref, onMounted } from 'vue'
  import axios from 'axios'
  import { ElMessage } from 'element-plus'

  export default {
    name: 'App',
    setup () {
      const claudeLoginUrls = ref({})
      const gptUrls = ref({})
      const activeName = ref('Claude')
      const loading = ref(false)

      const fetchClaudeLoginUrls = async () => {
        loading.value = true
        try {
          const response = await axios.get('/api/get_claude_ids')
          claudeLoginUrls.value = response.data
        } catch (error) {
          console.error('Error fetching login URLs:', error)
          ElMessage.error('获取登录 URL 失败')
        } finally {
          loading.value = false
        }
      }
      const fetchGptLoginUrls = async () => {
        loading.value = true
        try {
          const response = await axios.get('/api/get_gpt_login_urls')
          gptUrls.value = response.data
        } catch (error) {
          console.error('Error fetching login URLs:', error)
          ElMessage.error('获取登录 URL 失败')
        } finally {
          loading.value = false
        }
      }
      const openClaudeLoginUrl = async (url) => {
        console.log("url is ", url)
        loading.value = true
        try {
          const response = await axios.post('/api/get_claude_login_url', { "id": url });
          const responseUrl = response.data.url; // Adjust according to your actual response structure
          window.open(responseUrl, '_blank');
        } catch (error) {
          console.error('Error fetching login URLs:', error)
          ElMessage.error('获取登录 URL 失败')
        } finally {
          loading.value = false
        }
      }

      const openLoginUrl = (url) => {
        window.open(url, '_blank')
      }

      const handleClick = (tab) => {
        if (tab.props.name === 'Chatgpt' && !Object.keys(gptUrls.value).length) {
          fetchGptLoginUrls()
        }
      }

      onMounted(() => {
        fetchClaudeLoginUrls()
      })
      return {
        claudeLoginUrls,
        gptUrls,
        activeName,
        loading,
        openLoginUrl,
        openClaudeLoginUrl,
        handleClick
      }
    }
  }
</script>
<style>
  /* This is an example, feel free to delete this code */
  .tooltip-container {
    --background: #333333;
    --color: #e8e8e8;
    position: relative;
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    font-size: 18px;
    font-weight: 600;
    color: var(--color);
    padding: 0.7em 1.8em;
    border-radius: 8px;
    text-transform: uppercase;
    height: 60px;
    width: 180px;
    display: grid;
    place-items: center;
    border: 2px solid var(--color);
  }

  .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    transform-origin: -100%;
    transform: scale(1);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .tooltip-container span:last-child {
    position: absolute;
    top: 0%;
    left: 100%;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 1;
    background-color: var(--background);
    z-index: -1;
    border: 2px solid var(--background);
    transform: scale(0);
    transform-origin: 0;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    display: grid;
    place-items: center;
  }

  .tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    background: var(--background);
    z-index: -1;
    border-radius: 8px;
    scale: 0;
    transform-origin: 0 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  }

  .tooltip::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background: var(--background);
  }

  .tooltip-container:hover .tooltip {
    top: -100%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    scale: 1;
    animation: shake 0.5s ease-in-out both;
  }

  .tooltip-container:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    color: white;
    border-color: transparent;
  }

  .tooltip-container:hover span:last-child {
    transform: scale(1);
    left: 0;
  }

  .tooltip-container:hover .text {
    opacity: 0;
    top: 0%;
    left: 100%;
    transform: scale(0);
  }

  @keyframes shake {
    0% {
      rotate: 0;
    }

    25% {
      rotate: 7deg;
    }

    50% {
      rotate: -7deg;
    }

    75% {
      rotate: 1deg;
    }

    100% {
      rotate: 0;
    }
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .header {
    padding: 10px 20px;
    background-color: #f0f2f5;
  }

  .main-content {
    flex: 1;
    overflow: hidden;
    width: 85%;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .el-tab-pane {
    flex: 1;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .demo-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .el-tabs__content {
    flex: 1;
    overflow-y: auto;
  }


  .text-center {
    text-align: center;
  }

  /* .el-row {
    margin-bottom: -20px;
  } */

  .el-col {
    margin-bottom: 20px;
  }

  .account-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .el-card__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer {
    padding: 10px 20px;
    background-color: #f0f2f5;
  }

  @media (max-width: 768px) {
    .main-content {
      width: 95%;
      padding: 10px;
    }
  }

  .text-svg {
    font: bold 24px sans-serif;
    fill: #333;
  }

  .highlight {
    fill: #007bff;
  }
</style>