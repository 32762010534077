<template>
  <el-container class="app-container">
    <el-header class="header">
      <svg xmlns="http://www.w3.org/2000/svg" class="header-svg" viewBox="0 0 200 50" height="40">
        <rect width="200" height="50" fill="none" />
        <text x="10" y="35" class="text-svg">
          Test<tspan class="highlight">2</tspan>API
        </text>
      </svg>
    </el-header>
    <el-main class="main-content">
      <div class="content-wrapper">
        <div class="input-section">
          <el-input v-model="sessionKey" placeholder="输入 Session Key" class="input-area" type="textarea" :rows="8" />
          <el-button type="primary" @click="processInput" class="check-button">
            <el-icon>
              <Search />
            </el-icon>
            检查
          </el-button>
        </div>
        <div class="output-section">
          <el-card class="output-card live" :body-style="{ maxHeight: '300px', overflowY: 'auto', padding: '10px' }">
            <template #header>
              <div class="card-header live-header">
                <div class="header-content">
                  <el-icon>
                    <CircleCheckFilled />
                  </el-icon>
                  <span>Live</span>
                </div>
                <el-tag type="success" size="small">{{ liveCount }}</el-tag>
              </div>
            </template>
            <div class="output-content">
              <p v-for="(item, index) in liveOutput" :key="index" class="output-item">
                <el-tooltip v-if="item.key.length > 50" :content="item.key" placement="top">
                  <span>{{ item.key.slice(0, 50) }}...</span>
                </el-tooltip>
                <span v-else>{{ item.key }}</span>
                <el-text class="mx-1" type="success">Success</el-text>
              </p>
            </div>


          </el-card>

          <el-card class="output-card die">
            <template #header>
              <div class="card-header die-header">
                <div class="header-content">
                  <el-icon>
                    <CircleCloseFilled />
                  </el-icon>
                  <span>Die</span>
                </div>
                <el-tag type="danger" size="small">{{ dieCount }}</el-tag>
              </div>
            </template>
            <div class="output-content">
              <p v-for="(item, index) in dieOutput" :key="index">
                {{ item.key }}
                <el-text class="mx-1">{{ item.status }}</el-text>
              </p>
            </div>
          </el-card>
        </div>
      </div>
    </el-main>
    <el-footer class="footer">
      <p class="text-center">© <a href="https://t.me/test2api" class="footer-link">test2api</a></p>
    </el-footer>
  </el-container>
</template>

<script setup>
  import { ref } from 'vue'
  import { Search, CircleCheckFilled, CircleCloseFilled } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'

  const sessionKey = ref('')
  const liveOutput = ref([])
  const dieOutput = ref([])
  const liveCount = ref(0)
  const dieCount = ref(0)

  const processInput = async () => {
    const lines = sessionKey.value.split('\n').filter(line => line.trim() !== '')

    if (lines.length > 200) {
      ElMessage.warning('输入行数超过200行，请减少输入数量。')
      return
    }

    const escapedInput = lines.join('\n')

    try {
      const response = await fetch('http://127.0.0.1:5000/api/test_claude', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: escapedInput
        }),
      })

      if (!response.ok) {
        throw new Error('API 请求失败')
      }

      const data = await response.json()
      processApiResponse(data)
    } catch (error) {
      ElMessage.error('API 调用出错：' + error.message)
    }
  }
  const processApiResponse = (data) => {
    // Reset the output arrays
    liveOutput.value = []
    dieOutput.value = []

    // Check if successResults and errorResults are provided
    if (data.successResults && Array.isArray(data.successResults)) {
      liveOutput.value = data.successResults.map(item => ({ key: item.token, status: 'Success' }))
      liveCount.value = liveOutput.value.length
      console.log(liveOutput.value)
      console.log(liveOutput.value.length)
      console.log(liveCount.value)
    } else {
      liveCount.value = 0
    }

    if (data.errorResults && Array.isArray(data.errorResults)) {
      dieOutput.value = data.errorResults.map(item => ({ key: item.token, status: item.error }))
      dieCount.value = dieOutput.value.length
    } else {
      dieCount.value = 0
    }
  }

</script>

<style scoped>
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f2f5;
  }

  .header {
    padding: 15px 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }

  .header-svg {
    height: 40px;
  }

  .text-svg {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 700;
    fill: #333;
  }

  .highlight {
    fill: #1890ff;
  }

  .main-content {
    flex: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
  }

  .content-wrapper {
    width: 100%;
    max-width: 1300px;
    display: flex;
    gap: 40px;
  }

  .input-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .input-area {
    margin-bottom: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .input-area:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
    transform: translateY(-2px);
  }

  .input-area :deep(.el-textarea__inner) {
    min-height: 320px !important;
    resize: none;
    border-radius: 12px;
    border: none;
    padding: 20px;
    font-size: 16px;
    line-height: 1.6;
  }

  .check-button {
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
    background-color: #1890ff;
    border-color: #1890ff;
    transition: all 0.3s ease;
  }

  .check-button:hover {
    background-color: #40a9ff;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
  }

  .output-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .output-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 700px;
  }

  .output-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .output-card.live {
    border-top: 4px solid #52c41a;
  }

  .output-card.die {
    border-top: 4px solid #f5222d;
  }

  .card-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;
  }

  .header-content {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
  }

  .live-header {
    color: #52c41a;
  }

  .die-header {
    color: #f5222d;
  }

  .output-content {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 300px;
    font-size: 15px;
    line-height: 1.6;
    color: #333;
    padding: 20px;
    padding-right: 25px;
    /* 增加右侧内边距，避免滚动条覆盖内容 */
  }

  .output-content p {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
  }

  .footer {
    background-color: #ffffff;
    color: #8c8c8c;
    text-align: center;
    padding: 25px 0;
    margin-top: auto;
    font-size: 15px;
  }

  .footer-link {
    color: #1890ff;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footer-link:hover {
    color: #40a9ff;
  }

  /* 自定义滚动条样式 */
  .output-content::-webkit-scrollbar {
    width: 10px;
  }

  .output-content::-webkit-scrollbar-track {
    background: #f0f2f5;
    border-radius: 5px;
  }

  .output-content::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 5px;
    transition: background 0.3s ease;
  }

  .output-content::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }

  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
    }

    .input-section,
    .output-section {
      width: 100%;
    }

    .output-item {
      flex-direction: column;
      align-items: flex-start;
    }

    .output-key {
      margin-right: 0;
      margin-bottom: 5px;
    }

    .input-area :deep(.el-textarea__inner) {
      min-height: 240px !important;
    }

    .main-content {
      padding: 20px;
    }
  }
</style>